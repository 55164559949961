/* CommingSoon.css */

.hero-container {
    position: relative;
    height: 100vh;
    background: url('../../assets/images/black-bg.png') no-repeat center center / cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Text color */
    padding: 20px; /* Add some padding */
    box-sizing: border-box; /* Ensure padding does not affect width */
  }
  
  .hero-content {
    text-align: center;
    max-width: 800px;
    padding: 20px;
  }
  
  .hero-logo {
    max-width: 150px; /* Adjust as needed */
    margin-bottom: 60px;
  }
  
  .hero-heading {
    font-size: 75px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 20px;
  }
  
  .hero-description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 25px;
    padding: 0 34px;
  }
  
  .hero-email {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .hero-email a {
    color: #0A99FE;
    text-decoration: underline;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    gap: 20px; /* Space between icons */
  }
  
  .social-icon {
    font-size: 30px;
    color: #fff;
    text-decoration: none;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .hero-content {
      padding: 20px 40px;
    }
    .hero-heading {
        font-size: 30px; /* Smaller font size for smaller screens */
    }
  
    .hero-description {
        font-size: 14px;
        padding: 0 20px; /* Adjust padding for smaller screens */
    }
  
    .hero-email {
        font-size: 0.9rem;
    }
  
    .hero-logo {
        max-width: 120px; /* Smaller logo for smaller screens */
        margin-bottom: 30px; /* Adjust spacing */
    }
  
    .social-icons {
        margin-top: 100px; /* Adjust margin for better fit */
    }
  
    .social-icon {
        font-size: 30px; /* Smaller icon size for mobile */
    }
  }
  
  /* Extra small screens (phones in portrait mode) */
  @media (max-width: 480px) {
    .hero-heading {
        font-size: 30px; /* Further reduce font size */
    }
  
    .hero-description {
        font-size: 12px; /* Even smaller font size */
        padding: 0 10px; /* Adjust padding */
    }
  
    .hero-email {
        font-size: 0.8rem;
    }
  
    .hero-logo {
        max-width: 130px; /* Even smaller logo */
        margin-bottom: 70px; /* Adjust spacing */
    }
  
    .social-icons {
        margin-top: 80px; /* Further adjust margin */
    }
  
    .social-icon {
        font-size: 30px; /* Smaller icon size */
    }
  }